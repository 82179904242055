import axios from "axios";
import appConfig from "configs/app.config";
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from "constants/api.constant";
import { toast, Notification } from "components/ui";
import { PERSIST_STORE_NAME } from "constants/app.constant";
import deepParseJson from "utils/deepParseJson";
import store from "../store";
import { onSignOutSuccess } from "../store/auth/sessionSlice";

const unauthorizedCode = [401];
const serverErrorCodes = [400, 403, 404, 500];

const getBaseURL = () => {
  if (process.env.NODE_ENV === "production") {
    return "https://reservation.api.korp.com.tr" + appConfig.apiPrefix;
  } else {
    return "https://localhost:44331" + appConfig.apiPrefix;
  }
};

const BaseService = axios.create({
  timeout: 60000,
  baseURL: getBaseURL(),
});

BaseService.interceptors.request.use(
  (config) => {
    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
    const accessToken = localStorage.getItem("token");

    if (accessToken) {
      config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BaseService.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response) {
      if (unauthorizedCode.includes(response.status)) {
        toast.push(
          <Notification title="Yetki Hatası" type="warning">
            {response.data.detail}
          </Notification>,
          {
            placement: "top-end",
            autoClose: 5000,
          }
        );
        store.dispatch(onSignOutSuccess());
      } else if (serverErrorCodes.includes(response.status)) {
        toast.push(
          <Notification title="Hata" type="danger">
            {response.data.detail}
          </Notification>,
          {
            placement: "top-end",
            autoClose: 5000,
          }
        );
      }
    }

    return Promise.reject(error);
  }
);

export default BaseService;
